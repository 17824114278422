<template>
  <div class="card card-custom card-stretch gutter-b card-shadowless  ">
     <MyTable1 :tableDetail="tableDetail">
       <template v-slot:dynamicComponent>
         <div class="" v-for="(cmp, cmp_index) in tableDetail.tableComponent" v-bind:key="cmp_index" >
              <component  :is="cmp.component" :componentDetail="cmp.detail"   >
              </component>
          </div> 
        
      </template>
     </MyTable1>
  </div>
  
</template>

<script>

import MyTable1 from "@/view/alex/eCouponMyTable1.vue";
import store from '@/core/services/store'; 

import { mapGetters } from "vuex";
import  sidebarImport from "@/view/alex/eCouponSidebarImport.vue";

export default {
  name: "Campaign",
  data() {
    return {
      
      sidebarDetail1: {

        fromHeader: "รายละเอียดแคมเปญ",
        formSubHeader: "ข้อมูลแคมเปญ",
        formClass: ["col-lg-12"],
          
        formButton:[
          {
            label: "เคลียร์",
            color: "warning",
            style: {'width': '120px'},
            type: "clear", 
            // apiUrl : "/v1/file/upload-excel",
            // storeAction: "coupon/addDataUnshift",
            // apiParam: "uid"
          },
          {
            label: "บันทึก",
            color: "success",
            style: {'width': '120px'},
            type: "submit", 
            storeAction: "campaign/submitData",
            nextAction: "callasync", //*** {redirect , callasync, call} */
            callAction: "campaign/loadData",
            callApi: "/ecoupon-api/ecoupon/shop/campaign-list",
            callParams: {},
            // redirectTo: "/brand-list",
            // apiUrl : "/ecoupon-api/ecoupon/add-campaign/".concat(this.$route.params.brandId),
            //*** FOR ONLY SHOP */
            apiUrl : "/ecoupon-api/ecoupon/shop/add-campaign",
            // apiParam: "uid"
          },
          
        ],
          formGroup:  [
               {
                type: "text",
                param: "name",
                required: true,
                min: 0,
                max: 100,
                // regex: "",
                label: "ชื่อแคมเปญ",
                width: "full" , //*** {full, half}
                placeholder: "ชื่อแคมเปญ",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
              {
                type: "text",
                param: "code",
                required: true,
                max: 3,
                // regex: "",
                label: "รหัสแคมเปญ",
                width: "full" , //*** {full, half}
                placeholder: "รหัสแคมเปญไม่เกิน 3 ตัวอักษร",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
              {
                type: "textarea",
                param: "description",
                required: true,
                min: 0,
                max: 500,
                // regex: "",
                label: "รายละเอียดแคมเปญ",
                width: "full" , //*** {full, half}
                placeholder: "คำอธิบาย รายละเอียดแคมเปญ",
                // icon: ["flaticon2-search-1", "text-muted"],
              },

              {
                type: "datetime",
                param: "startDateTime",
                required: true,
                // regex: "",
                label: "วันที่เริ่ม",
                width: "full" , //*** {full, half}
                // icon: ["flaticon2-search-1", "text-muted"],
              },

              {
                type: "datetime",
                param: "endDateTime",
                required: true,
                // regex: "",
                label: "วันสุดท้าย",
                width: "full" , //*** {full, half}
                // icon: ["flaticon2-search-1", "text-muted"],
              },

               {
                type: "uploadDefaultImage",
                param: "dataImage",
                defaultImage: "/public/media/0mine/defaultImage.png",
                required: false,
                // regex: "",
                label: "อัพโหลด image",
                width: "half" , //*** {full, half}
                mapTo: "dataImage",
                // placeholder: "ชื่อร้าน",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
          ]
      },

      tableDetail: {
           
            tableHeader: "Campaign list",
            // tableSubHeader: "ข้อมูลทั้งหมด",

            page:{
              show: false,
              topbottom: 1, //*** {1: show only bottom, 2: show only top, 3: show both }
              pageSize: 20,
              pageOption: [20,50,100,200,500],
            },

            useStore: "campaign",
             itemSelection: {
              show : false,
              store: "campaign",
              items : "campaign/selected",
            },
            tableAction: {
              show: false,
              display: "action",
              color: "primary",
              option: [
                {
                  type: "editPanel",  //*** {editPanel , editSideBar}  เอา panel หรือ slidebar ออกมาเพื่อเปลี่ยน data
                  panelName: "updateBrand",
                  display: "update",
                  action: "campaign/update", //*** ชื่อ panel หรือ sidebar */
                  param: "uid"
                },
                {
                  type: "delete", //*** delete เอา panel ขึ้รมาเตือนก่อนลบ 
                  display: "delete",
                  action: "campaign/update", //*** ชื่อ store action */
                  param: "uid"
                },
                {
                  type: "change", //*** ทำการเปลี่ยนแปลงเลย 
                  display: "update status",
                  action: "campaign/updateStatus", //*** ชื่อ store action */
                  param: "uid"
                },
                {
                  type: "redirect", //*** ทำการเปลี่ยนแปลงเลย 
                  display: "update status",
                  action: "/dashboard", //*** ชื่อ route ที่จะ redirect */
                  param: "uid"
                },
                
              ]

            },
            // tableClass : ['px-40'],
            reload: {
              apiUrl: "/ecoupon-api/ecoupon/shop/campaign-list",
              storeAction: "campaign/loadData",
              dataList : "campaign/items",
            },
            tableButton:  [
              

            ],

            //*** รูปบบข้อมูลที่อยู่แต่ละคอลัมน์ตาราง

            filterButton: {
              label : "search",
              color: "danger",
              apiUrl: "/ecoupon-api/ecoupon/campaign-list/".concat(this.$route.params.brandId),
              storeAction: "campaign/loadData",
              // dataList : "brand/items",
            },
            filterClear: {
              label : "clear",
              color: "warning",
              // apiUrl: "/ecoupon-api/ecoupon/brand-list",
              // storeAction: "brand/loadData",
              // dataList : "brand/items",
            },

          
            //*** ปุ่มบนตารางเพื่อจะ redirect หรือทำบางอย่าง e.g. export excel
            tableButton:  [
              // {
              //   text : "Add Campaign",
              //   color : "success",
              //   storeAction: "campaign/add",
              //   apiName: "filter-coupon",
              // },

            ],

            tableComponent:  [
              // {
              //   component: sidebarImport,
              //   detail: {
              //     componentId : "addCampaign",
              //     componentMessage : "Add Campaign",
              //     iconClass: ["fa", "fa-plus-circle", "icon-md"],
              //     content : [
              //       {
              //         type : "form",
              //         detail: {},
              //       }
              //     ]
              //   },
              // },

            ],

            //*** รูปบบข้อมูลที่อยู่แต่ละคอลัมน์ตาราง
            // filterButton: {
            //   apiUrl: "/ecoupon-api/filter/get-coupon",
            //   storeAction: "campaign/loadData",
            // },
            apiName: "filter-coupon",
            tableFilter:  [
              {
                type: "text",
                param: "searchText",
                mapTo: "t1",
                dataType: "string",
                placeholder: "search",
                icon: ["flaticon2-search-1", "text-muted"],
              },
              
             
              // { 
              //   type: "select",
              //   param: "status",
              //   mapTo: "t3",
              //   dataType: "string",
              //   label: "Status:",

              //   option: [
              //     {
              //       display: "ทั้งหมด",
              //       value: ""
              //     },
              //     {
              //       display: "active",
              //       value: "active"
              //     },
              //     {
              //       display: "disabled",
              //       value: "disabled"
              //     },

              //   ]
              // },

            ],

            //*** รูปบบข้อมูลที่อยู่แต่ละคอลัมน์ตาราง
            tableStructure:  [
              {
                columnName: "รูป", //** ชื่อ columns ที่จะปรากฏในตาราง */
                style:  {'width': '150px'},
                type: "image", //*** {image, text, status , date , progress, link, number} */
                mainText: "dataImage",
                
              },
           
              {
                columnName: "ชื่อแคมเปญ", //** ชื่อ columns ที่จะปรากฏในตาราง */
                width:  {'width': '30%'},
                type: "text", //*** {image, text, status , date , progress, link, number} */
                // mainText:  "text1",
                // mainTextStyle: {'font-size':'1.2rem', 'font-family': 'mitr'},
                linkText1: "name",
                linkText1Style: {'font-size':'1.2rem'},
                linkUrl1:  "campaignlink",
                subText: "description",
                // subText2: "text4",
              },

              // {
              //   columnName: "การใช้งาน", //** ชื่อ columns ที่จะปรากฏในตาราง */
              //   // width:  {'width': '50%'},
              //   type: "text", //*** {image, text, status , date , progress, link, number} */
              //   mainText:  "total",
              //   mainRedText: "used",
              //   // mainTextStyle: {'font-size':'1.2rem', 'font-family': 'mitr'},
              //   // linkText1: "name",
              //   // linkText1Style: {'font-size':'1.2rem'},
              //   // linkUrl1:  "campaignlink",
              //   // subText: "description",
              //   // subText2: "text4",
              // },
              {
                columnName: "วันที่เริ่ม", //** ชื่อ columns ที่จะปรากฏในตาราง */
                width: {'width': '150px'},
                type: "date", //*** {image, text, status , date , progress, link, number} */
                // mainText:  "text9",
                mainBlueText:  "startDateTime",
                // mainBlueText:  "text9",
                // subText: "text14",
                // subText2: "text12",
                format: "en",  //*** dash , slash , en , th */

              },

              {
                columnName: "วันสุดท้าย", //** ชื่อ columns ที่จะปรากฏในตาราง */
                width: {'width': '150px'},
                type: "date", //*** {image, text, status , date , progress, link, number} */
                // mainText:  "text9",
                mainBlueText:  "endDateTime",
                // mainBlueText:  "text9",
                // subText: "text14",
                // subText2: "text12",
                format: "en",  //*** dash , slash , en , th */

              },

            ] // end: tableStructure 
            ,
            data: []
      }
     
   }
  },

  async created() {

    this.tableDetail.tableComponent[0].detail.content[0].detail = this.sidebarDetail1;
    
    // console.log("this.$store.getters['brand/items'] >>> ",this.$store.getters["brand/items"]);
    // await this.$store.dispatch("campaign/loadData");

    // this.tableDetail.data = this.items;

  },
  computed: {
    // ...mapGetters(["couponList"])
  },

  components: {
    MyTable1,
    sidebarImport,
  },
  methods: {
    // setCheck(checked) {
    //   this.checked = checked;
    // }
  }
};
</script>
