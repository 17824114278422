<template>
  <div>
    <Form1  :formDetail="formDetail"></Form1>
  </div>
</template>


<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import KTUtil from "@/assets/js/components/util";
// import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";

import shapeFormat from '@/mixins/shapeFormat.js'
import Form1 from "@/view/alex/eCouponForm1.vue";
import router from '@/router';

export default {
  name: "BrandForm",
  mixins: [shapeFormat],
  
  data() {
    return {
      dataImg: null,
      uploadFile: "",
      
      formDetail: {
          formType: "update", //*** null, undefined , create, update
          formApiUrl: "/ecoupon-api/ecoupon/shop/load-single-brand",
          formDataId: "",
          formStore: "brand",
          formHeader: "รายละเอียดร้านค้า",
          formSubHeader: "ข้อมูลลูกค้าทั้งหมด",
          formButton:[
            // {
            //   label: "เพิ่มคูปองเครดิต",
            //   color: "info",
            //   style: {'width': '180px'},
            //   action: "storeAction",
            //   type:"storeAction",

            //   storeAction: "brand/addCredit",
            //   apiUrl : "/ecoupon-api/ecoupon/add-coupon-credit/".concat(this.$route.params.brandId),
            //   params: {},
            //   nextAction: "callasync", //*** {redirect , callasync, call} */
            //   callAction: "brand/loadFormData",
            //   callApi: "/ecoupon-api/ecoupon/shop/coupon-list/".concat(this.$route.params.brandId),
            //   apiParam: "uid",
            //   param: "uid"
            // },
            // {
            //   label: "บันทึก",
            //   color: "success",
            //   style: {'width': '150px'},
            //   action: "submit",
            //   type:"submit",
            //   apiUrl : "/ecoupon-api/ecoupon/shop/update-brand",
            //   storeAction: "brand/updateData",
            //   apiParam: "uid"
            // },
          ],
          formGroup:  [
             
              {
                type: "review2",
                param: "name",
                // required: true,
                // regex: "",
                label: "ชื่อร้านค้า",
                width: "full" , //*** {full, half}
                mapTo: "name",
                // placeholder: "ชื่อร้าน",
                // icon: ["flaticon2-search-1", "text-muted"],
              },
              // {
              //   type: "textarea",
              //   param: "description",
              //   required: true,
              //   min: 0,
              //   max: 500,
              //   // regex: "",
              //   label: "รายละเอียดร้านค้า",
              //   width: "full" , //*** {full, half}
              //   placeholder: "คำอธิบาย รายละเอียดร้านค้า",
              //   // icon: ["flaticon2-search-1", "text-muted"],
              // },


              {
                type: "review2",
                param: "credit",
                // required: true,
                // regex: "",
                label: "เครดิตคูปอง",
                width: "half" , //*** {full, half}
                mapTo: "credit",
                // placeholder: "ชื่อร้าน",
                // icon: ["flaticon2-search-1", "text-muted"],
              },

               {
                type: "review2",
                param: "smsCredit",
                // required: true,
                // regex: "",
                label: "เครดิต SMS",
                width: "half" , //*** {full, half}
                // mapTo: "credit",
                // placeholder: "ชื่อร้าน",
                // icon: ["flaticon2-search-1", "text-muted"],
              },

              // {
              //   type: "text",
              //   param: "redeemCode",
              //   // required: true,
              //   // regex: "",
              //   label: "รหัสสำหรับ redeem coupon",
              //   width: "full" , //*** {full, half}
              //   style: {'padding-top': '40px'},
              //   // mapTo: "name",
              //   // placeholder: "ชื่อร้าน",
              //   // icon: ["flaticon2-search-1", "text-muted"],
              // },

              //  {
              //   type: "text",
              //   param: "smsSender",
              //   // required: true,
              //   // regex: "",
              //   label: "ชื่อผู้ส่ง SMS",
              //   width: "full" , //*** {full, half}
              //   // style: {'padding-top': '40px'},
              //   // mapTo: "name",
              //   // placeholder: "ชื่อร้าน",
              //   // icon: ["flaticon2-search-1", "text-muted"],
              // },

              {
                type: "review3",
                param: "redeemCode",
                // required: true,
                // regex: "",
                label: "รหัสสำหรับ redeem coupon",
                width: "full" , //*** {full, half}
                style: {'padding-top': '40px'},
                // mapTo: "name",
                // placeholder: "ชื่อร้าน",
                // icon: ["flaticon2-search-1", "text-muted"],
              },

              //  {
              //   type: "text",
              //   param: "smsSender",
              //   // required: true,
              //   // regex: "",
              //   label: "ชื่อผู้ส่ง SMS",
              //   width: "full" , //*** {full, half}
              //   // style: {'padding-top': '40px'},
              //   // mapTo: "name",
              //   // placeholder: "ชื่อร้าน",
              //   // icon: ["flaticon2-search-1", "text-muted"],
              // },

              // {
              //   type: "text",
              //   param: "smsContent",
              //   // required: true,
              //   // regex: "",
              //   label: "ข้อความสำหรับ SMS",
              //   width: "full" , //*** {full, half}
              //   // style: {'padding-top': '40px'},
              //   // mapTo: "name",
              //   // placeholder: "ชื่อร้าน",
              //   // icon: ["flaticon2-search-1", "text-muted"],
              // },


              // { 
              //   type: "select",
              //   param: "packageSize",
              //   required: false,
              //   label: "Package Size",
              //   width: "half" , //*** {full, half}

              //   mapTo: "t3",
              //   dataType: "string",

              //   option: [
              //     {
              //       display: "เลือก package size",
              //       value: ""
              //     },
              //     {
              //       display: "เริ่มต้น (S)",
              //       value: "s"
              //     },
              //     {
              //       display: "กลาง (M)",
              //       value: "m"
              //     },
              //     {
              //       display: "ใหญ่ (L)",
              //       value: "l"
              //     },
                 

              //   ]
              // },
              
          ]
          
      }

    }
  },
  created() {
    this.formDetail.formDataId = this.$route.params.brandId;
    // this.packageEnd = this.addDay(new Date(this.packageStart), 7);
  },
  computed: {
   
   },
   components:{
     Form1,
   },
   watch:{
    //  curr(value){
    //    console.log("CURRENCY VALUE >>> ",value);
    //  },
    //  shopPhone(){
    //    this.shopPhoneStateCheck();
    //  },
    //  shopLine(){
    //    this.shopLineState = this.validate_text_required(3,this.shopLine);
    //   //  console.log("this.shopLine >>> ",this.shopLine);
    //   //  console.log("this.shopLineState >>> ",this.shopLineState);

    //  },
    //  shopName(){
    //    this.shopNameState = this.validate_text_required(0,this.shopName);
    //  },
    //  shopPackage(){
    //    this.shopPackageStateCheck();
    //  },
    //  shopCategory(){
    //    this.shopCategoryStateCheck();
    //  },
    //  username(){
    //    this.usernameCheck();
    //  },

    // shopStatusInfo(){
    //    this.shopStatusInfoStateCheck();
    //  },
    //  password(){
    //    this.passwordCheck();
    //  },
    //  packageStart(){
    //    this.packageStartCheck();

    //  },
    //   packageEnd(){
    //     this.packageEndCheck();

    //  },
    //  trialStart(){
    //    this.trialStartCheck();

    //  },
    //   trialEnd(){
    //     this.trialEndCheck();

    //  },
   },
  mounted() {


    

  },

  methods: {

    

    // trialOrProduction(statusInfo){
    //   if(statusInfo === 'production'){
    //     return "ใช้จริงเลย"
    //   }else if(statusInfo === 'trial'){
    //     return "ทดลองก่อน"
    //   }else{
    //     return "ไม่ระบุ"
    //   }
    // },
    // payment(paid){
    //   if(paid === true)
    //   {
    //     return "ชำระแล้ว"
    //   }else{
    //     return "ยังไม่ชำระ"
    //   }
    // },
    // nextStep(){
        
    //     // console.log("wizard.getStep() >>> ",this.myWizard.getStep());
    //     if(this.myWizard.getStep() === 1){
    //       this.usernameCheck();
    //       this.passwordCheck();
    //       if(this.usernameState === true && this.passwordState === true){
    //         this.myWizard.goNext();
    //         this.myWizardStep++;
    //       }
    //     }
    //     else if(this.myWizard.getStep() === 2){
    //       this.shopNameStateCheck();
    //       // this.shopPhoneStateCheck();
    //       // this.shopLineStateCheck();
    //       this.shopPackageStateCheck();
    //       this.shopCategoryStateCheck();
    //       this.shopStatusInfoStateCheck();
    //       if(this.shopNameState === true && this.shopPackageState === true && this.shopCategoryState === true && this.shopStatusInfoState === true){
    //         this.myWizard.goNext();
    //         this.myWizardStep++;
    //       }
    //     }

    // },
    // previousStep(){
    //   this.myWizard.goPrev();
    //   this.myWizardStep--;
    // },
    // shopPhoneStateCheck(){
    //   if(this.shopPhone.length >= 9)this.shopPhoneState = true; else  this.shopPhoneState = false;

    // },
    // shopLineStateCheck(){
    //   this.shopLineState = this.validate_text_required(3,this.shopLine);

    // },
    // shopNameStateCheck(){
    //   this.shopNameState = this.validate_text_required(0,this.shopName);

    // },
    // shopStatusInfoStateCheck(){
    //   this.shopStatusInfoState = this.validate_select(this.shopStatusInfo);
    //   // console.log("shopStatusInfoStateCheck >>> ",this.shopStatusInfoState , this.shopStatusInfo);

    // },
    // shopPackageStateCheck(){
    //   this.shopPackageState = this.validate_select(this.shopPackage);

    // },
    // shopCategoryStateCheck(){
    //   this.shopCategoryState = this.validate_select(this.shopCategory);
    // },

    // usernameCheck(){
    //   this.usernameState = this.validate_email(this.username);
    // },
    // passwordCheck(){
    //   this.passwordState = this.validate_text_required(6,this.password);
    // },

    // packageStartCheck(){
    //   this.packageStartState = this.validate_date(this.packageStart);
    // },

    // packageEndCheck(){
    //   this.packageEndState = this.validate_date(this.packageEnd);
    // },
    // trialStartCheck(){
    //   this.trialStartState = this.validate_date(this.trialStart);
    // },

    // trialEndCheck(){
    //   this.trialEndState = this.validate_date(this.trialEnd);
    // },

    
    // validate_date(date){
    //   if(date === null || date === undefined|| date === ""){
    //     return false;
    //   }else{
    //     return true;
    //   }
    // },
    // validate_text_required(textLength, text){
    //   return (text.length >= textLength && text.length > 0 && text.length !== null) ? true : false;
    // },
    // validate_select(value){
    //     if(value !== null && value !== "" && value !== undefined){
    //       return true;
    //     }else{
    //       return false;
    //     }
    //   },
    //   validate_email(email_address){
    //     if(email_address.length <= 0)
    //     {
    //       return false;
    //     }
    //     let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //     if (email_address.match(regexEmail)) {
    //       return true; 
    //     } else {
    //       return false; 
    //     }
    // },
    // async submit(e) {

    //   var checkState = false;

    //   if(this.usernameState && this.passwordState && this.shopNameState && this.shopCategoryState && this.shopPackageState && this.shopStatusInfoState){
    //     checkState = true;
    //   }

  

    //   if(checkState === false){
    //       this.disableBtn = false;
    //       Swal.fire({
    //         title: "",
    //         text: "ยังใส่ข้อมูลไม่ครบถ้วน",
    //         icon: "warning",
    //         confirmButtonClass: "btn btn-secondary"
    //       });
          
    //       return;
    //   }

    //   this.disableBtn = true;

    //   const data = {
    //     username: this.username,
    //     password: this.password,
    //     shopName: this.shopName,
    //     shopCode: this.shopCode,
    //     shopPackage : this.shopPackage,
    //     shopLine : this.shopLine,
    //     shopPhone : this.shopPhone,
    //     shopDescription: this.shopDescription,
    //     shopCategory: this.shopCategory,
    //     shopStatusInfo : this.shopStatusInfo,
    //     paid : this.paid,
    //     // ShopAge : this.shopAge,
    //     // ShopBranchNumber : this.shopBranchNumber,
    //     trialStart: this.formatDate(this.trialStart) ,
    //     trialEnd: this.formatDate(this.trialEnd),
    //     packageStart: this.formatDate(this.packageStart) ,
    //     packageEnd: this.formatDate(this.packageEnd),
    //   }


    //   var res1 = null;

    //   var theUrl = this.$store.state.common.baseUrl + "/office-api/shop/create-shop";
    //   try {
    //     res1 = await axios.post(
    //       theUrl, data, this.$store.state.auth.apiHeader
    //     );
    //   } catch (error) {
    //     // console.log(error);
    //       Swal.fire({
    //         title: "",
    //         text: "สร้างไม่สำเร็จ",
    //         icon: "warning",
    //         confirmButtonClass: "btn btn-warning"
    //       })
        
    //       ;
        
    //   }
      

    //   Swal.fire({
    //     title: "",
    //     text: "สร้างร้านค้าเรียบร้อยแล้ว โปรดทดลอง login เข้าระบบ",
    //     icon: "success",
    //     confirmButtonClass: "btn btn-secondary"
    //   })
    //   .then(result => {
    //     // console.log("confirm result >>> ",result)
    //     // if (result.value) {
    //       this.$router.push("/dashboard-customer")
    //     // }
    //   });
    //   ;

    //   e.preventDefault();
      
    // }
  }
};
</script>
